
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageIndexLayout} from 'layouts/CmsPageIndexLayout'
import {HomeHero} from 'components/HomeHero'


const layoutProps = {
  
};
const MDXLayout = CmsPageIndexLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <HomeHero scrollArrow={false} mdxType="HomeHero">
      <h1>{`Web Developer`}</h1>
      <p>{`Programmatore full-stack.`}</p>
    </HomeHero>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    